<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("Addtransfer") }}
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row text-sm-start d-flex justify-content-center">
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label">{{
              $t("documentnumber")
            }}</label>
            <input disabled class="form-control mb-4" />
          </div>
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label">{{
              $t("documentdate")
            }}</label>
            <input disabled class="form-control mb-4" />
          </div>
        </div>

        <div class="row text-sm-start d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label">{{
              $t("transferfrom")
            }}</label>
            <select class="form-select" v-model="form.fromWarehouseId">
              <option v-for="item in warehouses" :key="item" :value="item.id">
                {{ item.warehouse_code }} | {{ item.warehouse_short_name }} |
                {{ item.warehouse_full_name }}
              </option>
            </select>
          </div>

          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label">{{
              $t("transfertovan")
            }}</label>
            <select class="form-select" v-model="form.toVanId">
              <option v-for="item in vans" :key="item" :value="item.id">
                {{ item.van_code }} | {{ item.van_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row text-sm-start d-flex justify-content-center mb-4">
          <div class="col-sm-10">
            <label for="selectMainProduct" class="form-label">{{
              $t("annotation")
            }}</label>
            <input v-model="form.remark" class="form-control mb-4" />
          </div>
        </div>

        <div class="row text-end">
          <div class="col-sm-12">
            <button
              type="button"
              class="btn btn-sm btn-light btn-success"
              style="font-size: 14px; font-weight: bold"
              @click="openDialogSearchProcuct()"
            >
              {{ $t("addon") }}
            </button>
            <!-- <AddItem
              :dialogAddItem="dialogAddItem"
              :warehouseId="form.fromWarehouseId"
              @addProduct="addProduct"
              @closeDialogAddItem="closeDialogAddItem"
            /> -->
            <DialogSearchProduct
              :dialogSearchProduct="dialogSearchProduct"
              :warehouseId="form.fromWarehouseId"
              @closeDialogSearchProduct="closeDialogSearchProduct"
              @submitSearchProduct="submitSearchProduct"
            />
          </div>
        </div>

        <div class="table-responsive" style="padding-top: 3px">
          <table
            class="
              table table-hover table-rounded table-striped
              border
              gy-7
              gs-7
            "
          >
            <thead>
              <tr
                style="width: 100%"
                class="
                  fw-bold
                  fs-5
                  text-gray-800
                  border-bottom-2 border-gray-200
                  mw-200px
                "
              >
                <th>#</th>
                <th>Item code</th>
                <th>Item name</th>
                <th>{{ $t("amount") }}</th>
                <th>{{ $t("unit") }}</th>
                <th>{{ $t("action") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="dataItemsShow.length < 1">
                <td class="text-center" colspan="6">ไม่มีข้อมูล</td>
              </tr>
              <tr v-for="(item, index) in dataItemsShow" :key="index">
                <td></td>
                <td>{{ item.code }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.requestStockAmt }}</td>
                <td>{{ item.unit }}</td>
                <td>
                  <button
                    @mouseenter="hoverBBtn"
                    type="button"
                    class="
                      btn btn-link btn-color-muted btn-active-color-danger
                      p-0
                    "
                    @click="goToDelete(item, index)"
                  >
                    <i class="bi bi-trash3-fill"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- <div class="row d-flex justify-content-center mt-4">
          <div class="col-sm-8">
            <label for="selectMainProduct" class="form-label">หมายเหตุ</label>
            <input class="form-control mb-4" />
            <label for="selectMainProduct" class="form-label"
              >โอนเข้าคลัง</label
            >
            <input class="form-control mb-4" />
          </div>

          <div class="col-sm-4">
            <label for="selectMainProduct" class="form-label"
              >มูลค่ารวม(ก่อนVAT)</label
            >
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label">ส่วนลดรวม</label>
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label">VAT</label>
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label"
              >มูลค่าสุทธิ</label
            >
            <input disabled class="form-control" />
          </div>
        </div> -->
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-light btn-primary me-2"
            style="font-size: 14px; font-weight: bold"
            @click="submittransaction()"
          >
            {{ $t("save") }}
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            style="font-size: 14px; font-weight: bold"
            @click="cancel"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import DialogSearchProduct from "./components/dialogSearchProduct.vue";
import AddItem from "./components/dialogAddItem.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";
import transferVANApi from "@/api/transfer";

export default {
  components: {
    AddItem,
    DialogSearchProduct,
  },
  setup() {
    document.title = "CHOMTHANA | สร้างใบโอนสินค้า";
  },
  data: () => ({
    searchProduct: "",
    isSubmit: false,
    isValidRetailPrice: false,
    dialogAddItem: false,
    form: {
      fromWarehouseId: null,
      toVanId: null,
      remark: "",
    },
    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
    dataItemsShow: [],
    warehouses: [],
    vans: [],
    dialogSearchProduct: false,
  }),

  created() {
    this.getAllWarehouse();
    this.getAllVan();
  },
  methods: {
    submitSearchProduct(item) {
      console.log("addProduct", item);
      // if (this.dataItemsShow.length >= 1) {
      //   for (let index = 0; index < this.dataItemsShow.length; index++) {
      //     if (this.dataItemsShow[index].productId == item.productId) {
      //       if (
      //         this.dataItemsShow[index].requestStockAmt +
      //           item.requestStockAmt <=
      //         this.dataItemsShow[index].stockAmt
      //       ) {
      //         console.log(
      //           this.dataItemsShow[index].requestStockAmt,
      //           item.requestStockAmt
      //         );
      //         this.dataItemsShow[index].requestStockAmt =
      //           this.dataItemsShow[index].requestStockAmt +
      //           item.requestStockAmt;
      //       } else {
      //         Swal.fire({
      //           icon: "error",
      //           title: "จำนวนสินค้าเกินจำนวนสต้อก",
      //           showConfirmButton: true,
      //         });
      //       }
      //     }
      //   }
      // } else if (this.dataItemsShow.length == 0) {
      //   console.log("ไม่ซ้ำ");
      //   let body = {
      //     productId: item.productId,
      //     code: item.productCode,
      //     name: item.productName,
      //     productUnitId: item.productUnitId,
      //     requestStockAmt: item.requestStockAmt,
      //     unit: item.unitName,
      //     fromWarehouseId: this.form_warehouse_id,
      //   };
      //   this.dataItemsShow.push(body);
      // }
      if (this.dataItemsShow.length >= 1) {
        let isFound = this.dataItemsShow.filter(
          (element) => element.productId == item.productId
        );
        if (isFound.length == 1) {
          this.dataItemsShow.forEach((element) => {
            // console.log("ซ้ำ",element.requestStockAmt + item.requestStockAmt,element.stockAmt);
            if (element.productId == item.productId) {
              if (
                element.requestStockAmt + item.requestStockAmt <=
                item.stockAmt
              ) {
                console.log(element.requestStockAmt, item.requestStockAmt);
                element.requestStockAmt =
                  element.requestStockAmt + item.requestStockAmt;
              } else {
                Swal.fire({
                  icon: "error",
                  title: "จำนวนสินค้าเกินจำนวนสต้อก",
                  showConfirmButton: true,
                });
              }
            }
          });
        } else {
          let body = {
            productId: item.productId,
            code: item.productCode,
            name: item.productName,
            productUnitId: item.productUnitId,
            requestStockAmt: item.requestStockAmt,
            unit: item.unitName,
            fromWarehouseId: this.form_warehouse_id,
          };
          this.dataItemsShow.push(body);
        }
      } else if (this.dataItemsShow.length == 0) {
        console.log("ไม่ซ้ำ");
        let body = {
          productId: item.productId,
          code: item.productCode,
          name: item.productName,
          productUnitId: item.productUnitId,
          requestStockAmt: item.requestStockAmt,
          unit: item.unitName,
          fromWarehouseId: this.form_warehouse_id,
        };
        this.dataItemsShow.push(body);
      }
    },
    async getAllVan() {
      let responseData = [];
      try {
        responseData = await whApi.van.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.vans = responseData.data;
      }
    },
    addProduct(item) {
      console.log("addProduct", item);
      this.dataItemsShow.push(item);
    },
    async getAllWarehouse() {
      let responseData = [];
      try {
        responseData = await whApi.warehouse.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.warehouses = responseData.data;
      }
    },
    async submit() {
      await this.validateForm();
      this.isSubmit = true;
      let responseData = [];
      let body = {
        ...this.form,
        products: this.dataItemsShow,
        companyId: localStorage.getItem("companyId"),
      };

      try {
        responseData = await whApi.warehouse.updateVAN(body);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        // this.productstock = responseData.data[0];
        await this.submittransaction();
      }
    },
    async submittransaction() {
      if (!this.form.toVanId) {
        Swal.fire({
          icon: "error",
          title: "กรุณาเลือกVAN ปลายทาง",
          showConfirmButton: true,
        });
        this.dialogSearchProduct = false;
      } else {
        Swal.fire({
          icon: "question",
          title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
          confirmButtonText: `${this.$t("ok")} `,
          cancelButtonText: `${this.$t("cancel")} `,
          showCancelButton: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            let responseData = [];
            let body = {
              transaction_number: "",
              form_warehouse_id: this.form.fromWarehouseId,
              to_van_id: this.form.toVanId,
              products: this.dataItemsShow,
              remark: this.form.remark,
              status: "รอจัดของ",
              companyId: localStorage.getItem("companyId"),
            };
            try {
              responseData = await transferVANApi.transferWAREHOUSEtoVAN.create(
                body
              );
            } catch (error) {
              console.log(error);
            }
            if (responseData.response_status === "SUCCESS") {
              // this.productstock = responseData.data[0];
              Swal.fire({
                icon: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                showConfirmButton: true,
              }).then(() => {
                this.$router.go(-1);
              });
            }
          }
        });
      }
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    addItem() {
      this.dialogAddItem = true;
    },
    closeDialogAddItem() {
      this.dialogAddItem = false;
    },
    openDialogSearchProcuct() {
      if (this.form.fromWarehouseId) {
        this.dialogSearchProduct = true;
      } else if (!this.form.fromWarehouseId) {
        Swal.fire({
          icon: "error",
          title: "กรุณาเลือกคลังต้นทาง",
          showConfirmButton: true,
        });
        this.dialogSearchProduct = false;
      }
    },
    closeDialogSearchProduct() {
      this.dialogSearchProduct = false;
    },
    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2delete")} ${item.name} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      // let deleteResponse = [];
      // deleteResponse = await whApi.branch.delete(item.id);
      // if (deleteResponse.response_status === "SUCCESS") {
      //   Swal.fire({
      //     icon: "success",
      //     title: `${this.$t("deletesuccessfull")} `,
      //     showConfirmButton: false,
      //     timer: 1500,
      //   }).then(() => {
      //     this.dataItemsShow.splice(index, 1);
      //     this.tableItems.splice(index, 1);
      //     this.getAll(1);
      //   });
      // }
      this.dataItemsShow.splice(index, 1);
    },
  },
};
</script>
